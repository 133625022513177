<template>
  <button @click="$emit('clickAction')" type="button"
          class="group bg-white rounded-md flex items-center focus:outline-none">
     <span
         class="w-7 h-7 rounded-full border-2 border-dashed border-gray-300 flex items-center justify-center text-gray-400">
       <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
         <path fill-rule="evenodd"
               d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
               clip-rule="evenodd"></path>
       </svg>
     </span>
    <span class="ml-4 text-sm text-gray-900">{{ name }}</span>
  </button>
</template>

<script>
export default {
  name: 'AddNewButton',
  props: {
    name: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
