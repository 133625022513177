<template>
<div
    @mouseover="showEditable = true"
    @mouseleave="showEditable = false"
    class="w-full grid grid-cols-10">
  <div class="col-span-9 flex items-center">
    <div class="flex items-center">
      <svg  class="flex-shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
        <path fill-rule="evenodd" d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z" clip-rule="evenodd" />
      </svg>
    </div>
    <div class="truncate">
      <a :href="file.url" target='_blank' class="ml-2 inline text-sm text-gray-900">{{ file.name }}</a>
    </div>
  </div>
  <div v-if="!isPublicPage" class="ml-4 mt-2 flex-shrink-0 absolute right-0 attachment_box">
    <DeleteAttachmentDropDown
        v-if="showEditable || (selectedItem === String(file.id))"
        @handleSelectItem="handleSelectItem"
        @openDeleteAttachmentFile="$emit('openDeleteAttachmentFile', $event)"
        :file="file"
    />
  </div>
</div>
</template>

<script>
import DeleteAttachmentDropDown from '@/components/Main/AttachmentFiles/AttachmentInputField/DeleteAttachmentDropDown/DeleteAttachmentDropDown';

export default {
  name: 'AttachmentItem',
  components: {
    DeleteAttachmentDropDown
  },
  props: {
    file: {
      required: true
    },
    selectedItem: String,
    isPublicPage: Boolean
  },
  data() {
    return {
      showEditable: false,
      isOpenEditAction: false
    }
  },
  methods: {
    handleSelectItem(selectedItem) {
      this.$emit('handleSetSelectedItem', selectedItem);
      this.isOpenEditAction = true;
    }
  }
}
</script>

<style scoped>
.attachment_box:before {
  position: absolute;
  content: '';
  background: linear-gradient(to left, rgba(255, 255, 255, 1) 48%, rgba(255, 255, 255, 0) 80%);
  width: 170px;
  height: 24px;
  right: 0;
}

</style>
