<template>
  <div>
    <DeleteItemModal
      v-if="showModal"
      :title="$t('deleteAttachmentModal.title')"
      :description="$t('deleteAttachmentModal.description')"
      @handleCloseModal="showModal = false"
      @handleConfirm="handleDeleteItem"
    />
    <div v-if="files">
        <ul class="w-full border-b border-gray-200 divide-y divide-gray-200">
          <li
            v-for="(file, index) in files" :key="index"
            class="w-full flex justify-between relative h-42p">
          <AttachmentItem
              :selectedItem="String(selectedItem)"
              :file="file"
              :isPublicPage="isPublicPage"
              @openDeleteAttachmentFile="openDeleteAttachmentFile"
              @handleSetSelectedItem="handleSetSelectedItem"
          />
          </li>
        </ul>
    </div>
  </div>
</template>

<script>
import DeleteAttachmentDropDown from '@/components/Main/AttachmentFiles/AttachmentInputField/DeleteAttachmentDropDown/DeleteAttachmentDropDown';
import DeleteItemModal from '@/components/Modals/DeleteItemModal/DeleteItemModal';
import AttachmentItem from '@/components/Main/AttachmentFiles/Attachments/AttachmentItem/AttachmentItem';

export default {
  name: 'Attachments',
  components: {
    AttachmentItem,
    DeleteAttachmentDropDown,
    DeleteItemModal
  },
  props: {
    files: {
      type: Array,
      required: true
    },
    isPublicPage: Boolean
  },
  data() {
    return {
      showModal: false,
      selectedAttachmentFile: {},
      selectedItem: ''
    }
  },
  methods: {
    handleDeleteItem() {
      this.showModal = false;
      this.emitDeleteAttachmentFile();
    },
    emitDeleteAttachmentFile() {
      this.$emit('deleteAttachmentFile', {
        selectedAttachmentFile: this.selectedAttachmentFile
      });
    },
    openDeleteAttachmentFile(item) {
      this.showModal = true;
      this.selectedAttachmentFile = item;
    },
    handleSetSelectedItem(id) {
      this.selectedItem = id;
    }
  }
}
</script>

<style scoped>

</style>
