<template>
  <div class="flex items-start justify-start gap-2 flex-col">
    <label v-if="label" class="text-sm font-medium text-gray-500">{{ label }}</label>
    <textarea
        :rows="rows"
        :name="inputName"
        :id="inputName"
        v-bind:value="value"
        @input="handleOnInput"
        :placeholder="placeholder"
        class="resize-none shadow-sm focus:outline-none focus:ring-gray-300 focus:border-gray-300 focus:ring-opacity-0 block w-full sm:text-sm border-gray-300 rounded-md" />
  </div>
</template>

<script>
export default {
  name: 'FormTextArea',
  props: {
    inputName: String,
    label: {
      type: String,
      'default': ''
    },
    placeholder: {
      type: String,
      'default': ''
    },
    rows: {
      type: String,
      'default': '4'
    },
    value: String | Number
  },
  methods: {
    handleOnInput(event) {
      this.$emit('handleInputChange', this.inputName, event.target.value);
    }
  }
}
</script>

<style scoped>

</style>
