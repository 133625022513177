<template>
  <div class="relative inline-block text-left z-40" v-click-outside="closeDropdown">
    <div>
      <button
          @click.prevent="handleOpenDropDown"
          type="button"
          class="rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none"
          aria-expanded="true" aria-haspopup="true">
        <DropDownThreeDots />
      </button>
    </div>
    <div
        v-if="isOpen"
        class="shadow-lg mx-3 origin-top-right absolute right-1 w-48 mt-1 rounded-md 2xl:hover:z-30 bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none"
        role="menu" aria-orientation="vertical">
      <div class="py-1" role="none" @click="handleDeleteItem">
        <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">{{ $t('main.delete') }}</a>
      </div>
<!--      <div class="py-1" role="none">
        <a
            :href="file.url"
            target='_blank'
            class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
            role="menuitem"
            @click.prevent="handleDownloadFile"
        >
          Download
        </a>
      </div>-->
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';
import DropDownThreeDots from '@/components/Icons/DropDownThreeDots';

export default {
  name: 'DeleteAttachmentDropDown',
  components: {
    DropDownThreeDots
  },
  directives: {
    ClickOutside
  },
  props: {
    file: {
      required: true
    }
  },
  data() {
    return {
      isOpen: false
    }
  },
  methods: {
    handleOpenDropDown() {
      this.isOpen = !this.isOpen;
      this.$emit('handleSelectItem', this.file.id);
    },
    closeDropdown() {
      this.isOpen = false;
      this.$emit('handleSelectItem', '');
    },
    handleDeleteItem() {
      this.closeDropdown();
      this.$emit('openDeleteAttachmentFile', this.file);
    },
    handleDownloadFile() {
      fetch(this.file.url)
        .then(response => response.blob())
        .then(blob => {
          const a = document.createElement('a');
          a.href = URL.createObjectURL(blob);
          a.download = this.file.url.split('/').pop();
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        });
    }
  }
}
</script>

<style scoped>

</style>
