<template>
  <svg xmlns="http://www.w3.org/2000/svg" class="text-gray-400 group-hover:text-gray-500 mr-3 " width="25" fill="none" viewBox="0 0 24 24" stroke="#132c40">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
  </svg>
</template>

<script>
export default {
  name: 'UploadFileIcon'
}
</script>

<style scoped>

</style>
