<template>
  <div>
    <div class="w-full flex items-start justify-start flex-col gap-1 mt-1">
      <div class="w-full border-b">
        <h3 class="text-sm font-medium text-gray-500 pb-2">{{ $t('customer.fields.attachments') }}</h3>
      </div>
    </div>
    <Attachments
        v-if="files.length"
        :files="files"
        :isPublicPage="isPublicPage"
        @deleteAttachmentFile="$emit('deleteAttachmentFile', $event)"
    />
    <div v-if="!isPublicPage" class="relative border-b pb-2 pt-2" v-click-outside="closeDropdown">
      <AddNewButton
          :name="$t('global.buttons.add_new')"
          @clickAction="isOpen = !isOpen"
      />
      <AttachmentDropZone
          :isOpen="isOpen"
          @handleAddAttachments="handleAddAttachments"
      />
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'
import Attachments from '@/components/Main/AttachmentFiles/Attachments/Attachments';
import AddNewButton from '@/components/Main/Buttons/AddNewButton/AddNewButton';
import UploadFileIcon from '@/components/Icons/UploadFileIcon';
import AttachmentDropZone from '@/components/Main/AttachmentFiles/AttachmentInputField/AttachmentDropZone/AttachmentDropZone';

export default {
  name: 'AttachmentInputField',
  components: {
    AttachmentDropZone,
    UploadFileIcon,
    AddNewButton,
    Attachments
  },
  directives: {
    ClickOutside
  },
  props: {
    files: {
      type: Array,
      required: true
    },
    isPublicPage: {
      type: Boolean,
      'default': false
    }
  },
  data() {
    return {
      isOpen: false
    }
  },
  methods: {
    closeDropdown() {
      this.isOpen = false;
    },
    handleAddAttachments(files) {
      this.$emit('handleAddAttachments', files);
      this.closeDropdown();
    }
  }
}
</script>

<style scoped>

</style>
