<template>
  <div>
    <ul class="space-y-8">
      <li v-for="(comment, index) in comments" :key="index">
        <div v-if="comment.author" class="flex space-x-3">
          <div class="flex-shrink-0">
            <img
                v-if="comment.author.avatar"
                class="h-10 w-10 rounded-full"
                :src="comment.author.avatar"
                alt="">
            <div v-else>
              <div class="h-9 w-9 rounded-full bg-gray-200 flex items-center text-center">
                <span class="block w-full">{{ comment.author.name | getNameAbbr }}</span>
              </div>
            </div>
          </div>
          <div>
            <div class="mt-2 text-sm space-x-2">
              <span class="font-medium text-gray-900">{{ comment.author.name }}</span>
              <span class="text-gray-500 font-medium">·</span>
              <span class="text-gray-500 font-medium">{{ comment.ago }}</span>
            </div>
            <div class="mt-1 text-sm text-gray-700">
              <p>{{ comment.comment }}</p>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'CommentsList',
  props: {
    comments: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
