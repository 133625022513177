<template>
  <section>
    <div>
      <div class="py-5">
        <h2 class="text-lg font-medium text-gray-900">{{ $t('commentBox.comments') }}</h2>
      </div>
      <div class="py-2">
        <div class="flex">
          <div class="flex-shrink-0">
            <img
                v-if="accountAvatar"
                class="h-10 w-10 rounded-full"
                :src="accountAvatar"
                alt="avatar">
            <div v-else-if="!accountAvatar && !isPublicPage">
              <div class="h-9 w-9 rounded-full bg-gray-200 flex items-center text-center">
                <span class="block w-full">{{ authorName | getNameAbbr }}</span>
              </div>
            </div>
          </div>
          <div v-if="!isPublicPage" class="min-w-0 flex-1">
            <div class="ml-3">
              <FormTextArea
                  placeholder="Add a note"
                  inputName="comment"
                  :value="comment"
                  @handleInputChange="handleInputChange"
              />
            </div>
            <div class="mt-3 flex items-center justify-end">
              <MainButton
                  :name="$t('task.comment')"
                  @clickAction="addNewComment"
              />
            </div>
          </div>
        </div>
      </div>
      <CommentsList :comments="commentsList"/>
    </div>
  </section>
</template>

<script>
import MainButton from '@/components/Main/Buttons/MainButton/MainButton'
import FormTextArea from '@/components/Main/Form/FormTextArea/FormTextArea'
import { mapActions, mapGetters } from 'vuex'
import CommentsList from '@/components/Main/CommentBox/CommentsList/CommentsList'

export default {
  name: 'CommentBox',
  props: {
    accountAvatar: {
      type: String,
      'default': ''
    },
    comments: {
      type: Array,
      'default': Array
    },
    ownerId: Number | String,
    prefix: {
      type: String,
      required: true
    },
    isPublicPage: {
      type: Boolean,
      'default': false
    },
    authorName: String
  },
  components: {
    CommentsList,
    FormTextArea,
    MainButton
  },
  data() {
    return {
      comment: '',
      commentsList: []
    }
  },
  computed: {
    ...mapGetters([
      'createCommentSuccess',
      'getCreatedComment',
      'createCommentResponseMessage'
    ])
  },
  watch: {
    createCommentSuccess(newValue) {
      if (newValue) {
        this.toastMessage(this.createCommentResponseMessage, 'success');
        this.commentsList = [this.getCreatedComment, ...this.commentsList];
      }
    },
    comments(newValue) {
      this.commentsList = newValue;
    }
  },
  mounted() {
    this.commentsList = this.comments;
  },
  methods: {
    ...mapActions({
      handleCreateNewComment: 'createNewComment'
    }),
    handleInputChange(key, value) {
      this.comment = value;
    },
    addNewComment() {
      this.comment.length && this.handleCreateNewComment({
        comment: this.comment, prefix: this.prefix, id: this.ownerId
      });
      this.comment = '';
    },
    toastMessage(message, type) {
      this.$toast.clear();
      this.$toast.open({ message, type })
    }
  }
}
</script>

<style scoped>

</style>
