import { render, staticRenderFns } from "./CommentsList.vue?vue&type=template&id=94f5d2c6&scoped=true&"
import script from "./CommentsList.vue?vue&type=script&lang=js&"
export * from "./CommentsList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "94f5d2c6",
  null
  
)

export default component.exports