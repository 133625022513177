<template>
  <div
      v-if="isOpen"
      class="absolute z-50 min-h-150p mt-1 w-full bg-white max-h-60 rounded-md py-1 text-base overflow-auto focus:outline-none sm:text-sm border border-gray-300">
    <div
        @dragover.prevent="dragOver"
        @drop="dropHandler"
        @dragleave="dragLeave"
        :draggable="true"
        class="flex flex-col w-full items-center min-h-150p justify-center cursor-pointer">
      <label for="file-upload" ref="brDropzone" class="br_dropzone flex flex-col w-full items-center min-h-150p justify-center cursor-pointer relative cursor-pointer bg-white rounded-md font-medium">
        <div><UploadFileIcon /></div>
        <input id="file-upload" name="file-upload" type="file" class="sr-only" ref="attachmentFile" multiple @change="handleFileChange">
        <div><span class="mt-1 text-sm text-gray-900">{{ $t('main.dropYourFilesHereOrClickToUpload') }}</span></div>
      </label>
    </div>
  </div>
</template>

<script>
import UploadFileIcon from '@/components/Icons/UploadFileIcon';

export default {
  name: 'AttachmentDropZone',
  components: { UploadFileIcon },
  props: {
    isOpen: {
      type: Boolean,
      required: true
    }
  },
  methods : {
    handleFileChange() {
      this.$emit('handleAddAttachments', this.$refs.attachmentFile.files);
    },
    dragOver() {
      this.$refs.brDropzone.classList.add('addClass');
    },
    dropHandler(event) {
      event.preventDefault();
      this.$emit('handleAddAttachments', event.dataTransfer.files);
    },
    dragLeave() {
      this.$refs.brDropzone.classList.remove('addClass');
    }
  }
}
</script>

<style scoped>
.br_dropzone.addClass {
  background-color: #eee;
  border: 1px dashed #132c40;
}
</style>
